import PropTypes from 'prop-types'
import React , {useState,useEffect} from "react"
import { 
    Modal, 
    ModalBody ,  
    Alert
} from "reactstrap"
import { Formiz, useForm } from '@formiz/core'
import { InputField } from "../forms/InputField.js";
import { SelectField } from '../forms/SelectField.js';
import { useCompanyInventory } from '../../hooks/inventoryHook.js';
import {LoadingIndicator} from '../../components/Common'
import { useEquipment } from '../../hooks/equipmentHook.js';
import { TextAreaField } from '../forms/TextAreaField.js';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export function EquipmentModal({isOpen,onCloseClick,projectId}){
    const [isitemSource,setItemSource] = useState(true)
    const {companyInventory} = useCompanyInventory()
    const [isSaving,setSaving] = useState(false)
    const { addInvEquipment,isEquipmentLoading} = useEquipment(onCloseClick,projectId)


    const equipmentForm = useForm()
    const handleSubmit = async (values) => {
        // console.log(values)
        let results = {
          ...values,
          projectId: projectId,
          itemSource: isitemSource
      }

        // if(isitemSource) {
        //     results = {
        //         ...values,
        //         projectId: projectId,
        //         itemSource: isitemSource
        //     }
        // } else {
        //     results = {
        //         ...values,
        //         projectId: projectId,
        //         itemSource: isitemSource
        //     }
        // }

        try {
            await addInvEquipment(results)      
          } catch (error){
            console.log("error" , error)
          }
        // console.log("results",results)
    }

    const handleItemSource = (type) => {
        setItemSource(prev => !prev)
      }

    const inventoryItems = companyInventory.map((item) => {
        return {
            label: item.inventoryName , value: item._id
        }
    })  

    return (
      <Modal isOpen={isOpen} toggle={onCloseClick} centered={true} size={"lg"}>
        <Formiz connect={equipmentForm} onValidSubmit={handleSubmit}>
          <form
            className="w-100 needs-validation"
            noValidate
            onSubmit={equipmentForm.submit}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Adding Equipment
              </h5>
              <button
                type="button"
                onClick={onCloseClick}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body d-flex flex-column w-100">
            <div className="d-flex flex-row">
                <div className={"col-6 p-2"}>
                  <div className="mb-1">
                    <label htmlFor="equipment-name" className="form-Label">
                      Select Item Source
                    </label>
                    <SelectField
                      name={"capitalInvestment"}
                        required={"please select a source "}
                      data={[
                        { label: "Self", value: true },
                        { label: "loaned", value: false },
                      ]}
                      defaultOption="Select item source"
                    />
                  </div>

                  
                  <div className="mb-1">
                    <label className="form-Label">Owner</label>
                    <InputField
                      name={"owner"}
                        required={"Please enter the owner of the equipment"}
                      type="text"
                      isDefault={false}
                    />
                  </div>
                  <div style={{marginTop: "2rem"}} className="mb-1" >
                    <label htmlFor="" className="form-Label">
                      Rate
                    </label>
                    <SelectField
                      name={"rate"}
                      //   required={"please select an item "}
                      data={[
                        { label: "hour", value: "hour" },
                        { label: "day", value: "day" },
                      ]}
                      defaultOption="Select rate"
                    />
                  </div>
                  {/* <div style={{ marginTop: "0rem" }} className="mb-1">
                    <label className="form-Label">Borrow Date</label>
                    <InputField
                      name={"borrowDate"}
                      //   required={"Please select an entry date"}
                      type="date"
                      placeholder=""
                      isDefault={false}
                    />
                  </div> */}
                 
                </div>
                <div className="col-6 p-2">
                  <div className="mb-1">
                    <label className="form-Label">Item Name</label>
                    <InputField
                      name={"equipmentName"}
                      required={"Please enter the name of the equipment"}
                      type="text"
                      isDefault={false}
                    />
                  </div>
                 
                  <div className="mb-1">
                    <label className="form-Label">Description</label>
                    <TextAreaField
                      name={"description"}
                      required={"please add a description"}
                      type="text"
                      placeholder={"Enter a description"}
                    />
                  </div>
                 
                  <div style={{marginTop: "0.7rem"}} className="mb-1">
                    <label className="form-Label">Total Quantity</label>
                    <InputField
                      name={"totalQuantity"}
                      required={"Please enter total quantity"}
                      type="number"
                      isDefault={false}
                    />
                  </div>
                  <div style={{marginTop: "0.7rem"}} className="mb-1">
                    <label className="form-Label">Hiring price</label>
                    <InputField
                      name={"ratePrice"}
                      //   required={"Please enter total quantity"}
                      type="number"
                      isDefault={false}
                    />
                  </div>
                  {/* <div className="mb-1">
                    <label className="form-Label">Return Date</label>
                    <InputField
                      name={"returnDate"}
                      //   required={"Please select an entry date"}
                      type="date"
                      placeholder=""
                      isDefault={false}
                    />
                  </div> */}
                </div>
              </div>
              {/* <div className="d-flex flex-row">
                <div className={isitemSource ? "col-12" : "col-6 p-2"}>
                  <div className="mb-3">
                    <label htmlFor="capitalInvestment" className="form-Label">
                      Select Item Source
                    </label>
                    <select
                      className="form-select"
                      onChange={(e) => handleItemSource(e.target.value)}
                      defaultValue={isitemSource}
                      required
                    >
                      <option value={true}>Self</option>
                      <option value={false}>Loaned</option>
                    </select>
                  </div>
                 
                  {isitemSource && (
                    <>
                      <div className="mb-1">
                        <label htmlFor="equipment-name" className="form-Label">
                          Select item
                        </label>
                        <SelectField
                          name={"companyInventoryId"}
                          data={inventoryItems}
                          defaultOption="Select item from company inventory"
                        />
                      </div>
                      <div className="mb-1">
                        <label className="form-Label">Total Quantity</label>
                        <InputField
                          name={"totalQuantity"}
                          type="number"
                          isDefault={false}
                        />
                      </div>
                      <div className="mb-1">
                        <label htmlFor="equipment-name" className="form-Label">
                          Rate
                        </label>
                        <SelectField
                          name={"rate"}
                          data={[
                            { label: "hour", value: "hour" },
                            { label: "day", value: "day" },
                          ]}
                          defaultOption="Select rate"
                        />
                      </div>
                      <div className="mb-1">
                        <label className="form-Label">Hiring price</label>
                        <InputField
                          name={"ratePrice"}
                          type="number"
                          isDefault={false}
                        />
                      </div>
                      <div className="mb-1">
                        <label className="form-Label">Return Date</label>
                        <InputField
                          name={"returnDate"}
                          type="date"
                          placeholder=""
                          isDefault={false}
                        />
                      </div>
                    </>
                  )}
                  
                    <>
                      <div className="mb-1">
                        <label className="form-Label">Owner</label>
                        <InputField
                          name={"owner"}
                          type="text"
                          isDefault={false}
                        />
                      </div>
                      <div style={{marginTop: "2rem"}} className="mb-1">
                        <label className="form-Label">Borrow Date</label>
                        <InputField
                          name={"borrowDate"}
                          type="date"
                          placeholder=""
                          isDefault={false}
                        />
                      </div>
                      <div className="mb-1">
                      <label htmlFor="equipment-name" className="form-Label">
                        Rate
                      </label>
                      <SelectField
                        name={"rate"}
                        data={[
                          { label: "hour", value: "hour" },
                          { label: "day", value: "day" },
                        ]}
                        defaultOption="Select rate"
                      />
                    </div>
                    </>
                 
                </div>
               
                  <div className="col-6 p-2">
                    <div className="mb-1">
                      <label className="form-Label">Item Name</label>
                      <InputField
                        name={"equipmentName"}
                        required={"Please enter the name of the equipment"}
                        type="text"
                        isDefault={false}
                      />
                    </div>

                    <div className="mb-1">
                      <label className="form-Label">Description</label>
                      <TextAreaField
                        name={"description"}
                        required={"please add a description"}
                        type="text"
                        placeholder={"Enter a description"}
                      />
                    </div>

                    <div className="mb-1">
                      <label className="form-Label">Return Date</label>
                      <InputField
                        name={"returnDate"}
                        //   required={"Please select an entry date"}
                        type="date"
                        placeholder=""
                        isDefault={false}
                      />
                    </div>
                    
                    <div className="mb-1">
                      <label className="form-Label">Hiring price</label>
                      <InputField
                        name={"ratePrice"}
                        //   required={"Please enter total quantity"}
                        type="number"
                        isDefault={false}
                      />
                    </div>
                  </div>
              
              </div> */}

              {/* {!isitemSource && (
                <>
                  <div className="mb-1">
                    <label className="form-Label">Item Name</label>
                    <InputField
                      name={"equipmentName"}
                      required={"Please enter the name of the equipment"}
                      type="text"
                      isDefault={false}
                    />
                  </div>
                  <div className="mb-1">
                    <label className="form-Label">Owner</label>
                    <InputField
                      name={"owner"}
                      //   required={"Please enter the name of the equipment"}
                      type="text"
                      isDefault={false}
                    />
                  </div>
                  <div className="mb-1">
                    <label className="form-Label">Description</label>
                    <TextAreaField
                      name={"description"}
                      required={"please add a description"}
                      type="text"
                      placeholder={"Enter a description"}
                    />
                  </div>
                  <div className="mb-1">
                    <label className="form-Label">Borrow Date</label>
                    <InputField
                      name={"borrowDate"}
                      //   required={"Please select an entry date"}
                      type="date"
                      placeholder=""
                      isDefault={false}
                    />
                  </div>
                  <div className="mb-1">
                    <label className="form-Label">Return Date</label>
                    <InputField
                      name={"returnDate"}
                      //   required={"Please select an entry date"}
                      type="date"
                      placeholder=""
                      isDefault={false}
                    />
                  </div>
                  <div className="mb-1">
                    <label htmlFor="equipment-name" className="form-Label">
                      Rate
                    </label>
                    <SelectField
                      name={"rate"}
                      //   required={"please select an item "}
                      data={[
                        { label: "hour", value: "hour" },
                        { label: "day", value: "day" },
                      ]}
                      defaultOption="Select rate"
                    />
                  </div>
                  <div className="mb-1">
                    <label className="form-Label">Hiring price</label>
                    <InputField
                      name={"ratePrice"}
                      //   required={"Please enter total quantity"}
                      type="number"
                      isDefault={false}
                    />
                  </div>
                </>
              )} */}
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={onCloseClick}
                  className="btn btn-secondary "
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary "
                  disabled={isEquipmentLoading}
                >
                  {isEquipmentLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <span>Add Equipment</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </Formiz>
        <ToastContainer />
      </Modal>
    );
}

EquipmentModal.propTypes = {
    onCloseClick: PropTypes.func,
    isOpen: PropTypes.bool,
    // projectId: PropTypes.string,
    // taskType: PropTypes.number,
    // data: PropTypes.object

}